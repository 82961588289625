<template>
  <loading-spinner class="position-relative bg-light py-5" v-if="isLoading">
    Loading certificates
  </loading-spinner>
  <div v-else>
    <div class="table-responsive border rounded">
      <table class="table lh-sm table-admin table-hover w-100">
        <thead>
          <tr>
            <th style="width: 1%" class="pe-0">#</th>
            <th>Certificate</th>
            <th style="width: 1%" class="text-center">Date</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(cert, index) in certificates"
            :key="cert._id"
            @click="currentMedia = cert.card"
          >
            <td style="width: 1%" class="pe-0">{{ index + 1 }}</td>
            <td class="text-nowrap text-truncate lh-sm">
              {{ cert.title }}
            </td>
            <td class="text-nowrap text-truncate text-center" style="width: 1%">
              {{ Helper.formatDate(cert.date) }}
            </td>
          </tr>
          <tr v-if="certificates.length < 1">
            <td colspan="3" class="text-center fst-italic text-muted">
              No certificate yet
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <media-viewer
      v-if="currentMedia"
      :url="this.Helper.formatMediaUrl(currentMedia)"
      :mime-type="this.Helper.getMediaMimeType(currentMedia)"
      @close="currentMedia = null"
    />
  </div>
</template>

<script>
import LoadingSpinner from "@/components/LoadingSpinner";
import MediaViewer from "@/components/Modals/MediaViewer";

export default {
  components: {
    MediaViewer,
    LoadingSpinner,
  },
  data() {
    return {
      isLoading: false,
      certificates: [],
      currentMedia: null,
    };
  },
  methods: {
    async getCRTCertificates() {
      return new Promise(async (resolve) => {
        this.isLoading = true;

        try {
          const { data } = await this.API.get(
            `crt-certificates?_limit=-1&user=${this.$store.state.user._id}&_sort=date:DESC`
          );

          this.certificates = data.filter((d) => d.card);
        } catch (error) {
          console.error(error);
        } finally {
          this.isLoading = false;

          resolve();
        }
      });
    },
  },
  async mounted() {
    let waitUserAvailable = async () => {
      this.isLoading = true;

      if (this.$store.state.user) {
        await this.getCRTCertificates();

        this.isLoading = false;
      } else {
        setTimeout(() => {
          waitUserAvailable();
        }, 1000);
      }
    };

    waitUserAvailable();
  },
};
</script>