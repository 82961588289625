<template>
  <div class="loading-spinner">
    <div class="bg-light p-3 rounded-circle border border-secondary">
      <img class="spinner-logo fa-spin" src="@/assets/logo.png" />
    </div>
    <p class="mt-3 mb-0">
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: "component-loading-spinner",
  props: ["lockedElement"],
  mounted() {
    if (this.lockedElement) {
      if (this.lockedElement == "body") {
        document.body.classList.add("overflow-hidden");
      } else {
        const elem = document.getElementById(this.lockedElement);

        if (elem) {
          elem.classList.add("overflow-hidden");
        }
      }
    }
  },
  beforeDestroy() {
    if (this.lockedElement) {
      if (this.lockedElement == "body") {
        document.body.classList.remove("overflow-hidden");
      } else {
        const elem = document.getElementById(this.lockedElement);

        if (elem) {
          elem.classList.remove("overflow-hidden");
        }
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.loading-spinner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba($color: #000000, $alpha: 0.75);
  z-index: 300;
}

.border-primary {
  border-width: 4px !important;
}
</style>